import React, { useEffect, useRef } from 'react'
import BaseWrap from './BaseWrap'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import Collapsed from './Collapsed'

export default _ => {
    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)
    const tl = useRef(null)

    return (
    <div className="">
        <BaseWrap id="whatWereDoing" fwdRef={ref} className={'min-screen2 bg-black bg-noise py-16'}>
            <div className='c-gray no-pad'>

                <div className="relative mb-4 lg:mb-8">
                    <div className="ff-pilat-wide text-lg md:text-xl lg:text-2xl uppercase leading-none">Organizations</div>
                </div>
                <div className="relative mb-10">
                    <div className="ff-pilat uppercase text-base-2 md:text-[1.2rem] lg:text-lg leading-none">We stand in solidarity with these organizations in the fight against book bans and the mission to ensure knowledge remains accessible to all.</div>
                </div>

                <div className='grid grid-cols-3 md:grid-cols-6 gap-10 mt-6 items-center'>
                    {
                        [0,1,2,3,4,5,6,7,8,9,10,11,12,13].map((img, i) => {
                            return (
                                <div key={i}>
                                    <img src={'/logos/' + img + '.png'} className='w-full' alt="" />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </BaseWrap>
    </div>
    )
}
